

import React, { useState, useEffect, useMemo } from 'react';
import { map, BehaviorSubject, Observable } from 'rxjs';
import { useDatabase } from './DatabaseContext';
import { MangoQuery, RxDatabase, RxQuery, RxDocument } from 'rxdb';
import { addNewEnvironment, archiveEnvironment } from '../state/Environments';
import { bind, Subscribe, state } from "@react-rxjs/core"

const useEnvironments = (db: RxDatabase, query: RxQuery<Environment>) => {
    const [Environments, setEnvironments] = useState<RxDocument<Environment>[]>([]);
    
    useEffect(() => {
        const Environments$ = query.$ as unknown as BehaviorSubject<RxDocument<Environment>[]>;
        const subscription = Environments$.subscribe(convs => {
            setEnvironments(convs);
        });
        
        // Cleanup subscription on component unmount
        return () => subscription.unsubscribe();
    }, [db, query]);
    
    return Environments;
};

const defaultQuery = (db: RxDatabase): RxQuery<Environment> => {
    return db.collections.environments
      .find()
      .sort({ updatedAt: 'desc' })
      .limit(50) as RxQuery<Environment>;
};

const getSearchQuery = (db: RxDatabase, search: string): RxQuery<Environment> => {
    return db.collections.environments
      .find({
          selector: {
              name: {
                  $regex: new RegExp(search),
                  $options: 'i'
              }
          }
      })
      .sort({ updatedAt: 'desc' })
      .limit(5) as RxQuery<Environment>;
}

// Remove the duplicate import statement for React
// import React from 'react';

interface ChatSelectorProps {
    selected: Environment | null;
    setSelected: React.Dispatch<React.SetStateAction<Environment | null>>;
}

const ChatSelector: React.FC<ChatSelectorProps> = ({ selected, setSelected }) => {

    const [search, setSearch] = useState<string>('');

    const db = useDatabase();

    const query = useMemo(() => {
        if (search) {
            return getSearchQuery(db, search);
        }
        return defaultQuery(db);
    }, [db, search]);


    const Environments = useEnvironments(db, query);
   
   
    return (
        <div className="w-[260px] bg-sidebar text-white border-black">
            {/* Add a search input for user to filter by keyword */}
            {/* <input 
                type="text" 
                placeholder="Search..."
                onChange={(e) => console.log(e.target.value)}
            /> */}
            {Environments.filter(Environment => {return Environment._deleted !== true}).map((Environment) => (
            
            <div 
                key={Environment.id}
                className={`border-1 border-black cursor-pointer ${selected?.id === Environment.id ? 'bg-gray-600' : 'hover:bg-gray-700'}`} 
                onClick={() => setSelected(Environment._data)}
            >
            <p>{Environment.name}</p>
            <button className="border-1" onClick={(e) => {
                e.stopPropagation();
                archiveEnvironment(db, Environment.id);
            }}>Trash</button>
        </div>
        ))}
            <button className="border-1 mt-5" onClick={() => setSelected(null)}>New</button>
        </div>
    );
    
}

export default ChatSelector;

