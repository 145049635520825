// Importing necessary libraries and methods
import { Observable, of, BehaviorSubject } from 'rxjs';
import cuid from 'cuid';
import { isRxDbConnectError } from '../utility';
import { MangoQuery, RxDatabase, RxQuery, RxCollection, RxDocument } from 'rxdb';
import { useDatabase } from '../components/DatabaseContext'; // Ensure you import from the correct path
import { useEffect, useState } from 'react';
import { interval } from "rxjs"
import { take } from "rxjs/operators"
import { bind, Subscribe, state } from "@react-rxjs/core"


export const addNewEnvironment = async (db: RxDatabase) => {
  const id = cuid();
  const agent = await db.collections.agents.findOne('localagent').exec() as RxDocument<Agent>;
  // const scarlet_id = await db.collections.agents.findOne().where('ai').eq(true).exec() as RxDocument<Agent>;
  // const steve_id = await db.collections.agents.findOne().where('ai').eq(true).exec() as RxDocument<Agent>;
  const newEnvironment = { id: id, name: 'New Environment', messages: [], _deleted: false, agentIds: [agent.id, 'scarlet', 'steve'], type:'chat' } as Environment;
  await db.collections.environments.insert(newEnvironment);  // Insert a new Environment into the database
}

export const archiveEnvironment =  async (db: RxDatabase, environmentId: string) => {
    const Environment = await db.collections.environments.findOne(environmentId).exec();
    if(Environment){
      Environment.update({ 
        $set: {_deleted: true} 
      })
    }
}

export const addMessage = async (db: RxDatabase, environmentId: string, message: Message) => {
  const agent = await db.collections.agents.findOne('localagent').exec() as RxDocument<Agent>;
  await db.collections.messages.insert({ id: cuid(), complete: true, agentId: agent.id, environmentId: environmentId, content: message.content, updatedAt: new Date().toISOString() });  // Insert a new message into the database
}









