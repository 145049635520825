import { FromSchema, JSONSchema } from "json-schema-to-ts";
export const agentSchema = {
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100
    },
    _deleted: {
      type: 'boolean'
    },
    name: {
      type: 'string'
    },
    type:{
      type: 'string' //human, code assistant, game contestant
    },
    avatarURL: {
      type: 'string'
    },
    bio: {
      type: 'string'
    },
    prompt: {
      type: 'string'
    },
    createdAt: {
      type: 'string',
      format: 'date-time'
    },
    updatedAt: {
      type: 'string',
      format: 'date-time'
    }
  },
  required: ['id', 'name', 'type']
} as const;
declare global {
    type Agent = FromSchema<typeof agentSchema>;
  }