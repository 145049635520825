import cuid from 'cuid';
import { MangoQuery, RxDatabase, RxQuery } from 'rxdb';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { map, BehaviorSubject, pairwise, filter } from 'rxjs';
import { useDatabase } from './DatabaseContext';
import Message from './Message';
import { AIAgent } from '../agents/Agent';
import { syncEnvironment } from '../connections/externalAPI';
import Cookies from 'js-cookie';

interface EnvProps {
    environment: Environment;
    localAgent: Agent;
}
const Environment:React.FC<EnvProps>  = ({environment, localAgent}) => {

    const agentInstancesRef = useRef<AIAgent[]>([]);
    const [inputMessage, setInputMessage] = useState("");
    const db = useDatabase();
    const [messages, setMessages] = useState<Message[]>([]);


    useEffect(() => {
        if (environment.public) {
            const syncEnvironmentAsync = async () => {
                const replicationState = await syncEnvironment(db, environment.id, localAgent.id);
                console.log('Replication State:', replicationState);
            };
            syncEnvironmentAsync();
        }
       
        bootstrapAIAgents(db, environment);

        const messages$ = db.collections.messages
        .find({
          selector: {
              environmentId: environment.id
          }
        })
        .sort({ createdAt: 'asc' })
        .limit(1000).$ as BehaviorSubject<Message[]>;

        const completeMessages$ = db.collections.messages
        .find({
          selector: {
              environmentId: environment.id,
              complete: true
          }
        })
        .sort({ createdAt: 'asc' })
        .limit(1000).$ as BehaviorSubject<Message[]>;

        // const messagesSub = messages$.subscribe(async messages => {
        //     //sort messages ascending based on updatedAt
        //    const sorted = messages.sort((a, b) => {
        //         return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
        //     });

        //     setMessages(messages);
        // });
        
        const completeMessagesSub = completeMessages$.pipe(
            map(messages => messages.filter(message => message.complete)),
            pairwise(),
            filter(([prevMessages, currMessages]) => currMessages.length > prevMessages.length),
            map(([, currMessages]) => currMessages)
        ).subscribe(async messages => {
            setMessages(messages);
            

            //if user isnt the host, dont query agents
            if (environment.hostAgent !== localAgent.id) {
                console.log('not host dont query agents');

               return;
            }

            let agentValues = new Map();
            for (const agent of agentInstancesRef.current) {
                agentValues.set(agent, Math.floor(Math.random() * 101));
            }
            const sortedAgents = Array.from(agentValues.entries()).sort((a, b) => a[1] - b[1]).map(entry => entry[0]);
            for (const agent of sortedAgents) {
                const readResult = await agent.readMessages(messages);
                if (readResult) {
                    break; // Exit the loop if readMessages returns true
                }
            }
        });
        return () => {
            agentInstancesRef.current.forEach(agent => agent.cleanup());
            // messagesSub.unsubscribe();
            completeMessagesSub.unsubscribe();
        };


    }, [localAgent]);

    async function bootstrapAIAgents(db: RxDatabase, env: Environment) {

        const agents = await db.agents.find({
            selector: {
                id: {
                    $in: env.agentIds
                },
                type: {
                    $ne: 'human'
                }
            }
        }).exec() as Agent[];
        agents.forEach(agent => {
            const existingAgent = agentInstancesRef.current.find(a => a.agentId === agent.id);
            if (!existingAgent) {
                const aiAgent = new AIAgent(db, agent.id, env.id);
                agentInstancesRef.current.push(aiAgent);
            }
        });
    }

    let tokenCount: number = 0;
    let maxTokens: number = 8000;


    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputMessage(e.target.value);
      };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {

    if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        const message: Message = {
            id: cuid(),
            complete: true,
            agentId: localAgent.id,
            content: inputMessage,
            environmentId: environment.id,
            updatedAt: new Date().toISOString(),
            createdAt: new Date().toISOString(),
            readBy: [localAgent.id]
        };
      console.log('new message', message.id);

        db.collections.messages.insert(message);
        db.collections.environments.upsert({
            ...environment,
            updatedAt: new Date().toISOString(),
        });
        setInputMessage('');
        }
    }

    return (
        <div className="pt-[80px] h-screen w-full chat-scroll bg-gray-800 relative flex flex-col overflow-y-scroll border-2 justify-between">
            <div className="flex justify-end p-4"></div>

            {environment && (
                <div>
                    <div className="flex flex-col p-0 w-full text-base mx-auto my-0">
                        {messages.map((message) => (
                            <div
                                key={message.id}
                                className="mx-auto my-0 w-[600px] justify-center min-h-[84px] p-4 text-white relative flex "
                            >
                                <Message initialMessage={message} />
                            </div>
                        ))}
                    </div>
                    <form className="sticky w-[668px] mx-auto bottom-0 flex items-center justify-center pb-4">
                        <textarea
                            value={inputMessage}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            className="resize-none text-white focus:outline-none bg-gray-700 relative flex-1 mx-4 my-2 p-4 border border-gray-900 rounded-xl"
                            placeholder="Type your message here..."
                            id="chat-input"
                        />
                    </form>
                    <div className="text-white mx-auto mt-4">
                        Agent Host: {environment.hostAgent}
                        Agent User: {localAgent.name}
                    </div>
                   
                   
                </div>
            )}
        </div>
    );
}
export default Environment;
