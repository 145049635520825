import { createRxDatabase, RxDatabase, addRxPlugin, RxError } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie';
import { environmentSchema } from '../schemas/Environment';
import { messageSchema } from '../schemas/Message';
import { agentSchema } from '../schemas/Agent';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import cuid from 'cuid';


addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBDevModePlugin);
addRxPlugin(RxDBQueryBuilderPlugin);

async function initializeDatabase(): Promise<RxDatabase> {

    const database = await createRxDatabase({
        name: 'scarlet123',
        storage: getRxStorageDexie()
    });

    await database.addCollections({
        environments: {
            schema: environmentSchema
        },
        messages: {
            schema: messageSchema
        },
        agents: {
          schema: agentSchema
        }
    });

    // Check if conversations collection is empty
    const allAgents = await database.collections.agents.find().exec();
    
    // If empty, add a placeholder conversation
    if (allAgents.length === 0) {


        await database.collections.agents.insert({
            id: 'bradley',
            name: 'Bradley',
            avatarURL: '/user.jpg',
            type: 'human'
        });
        await database.collections.agents.insert({
            id: 'katie',
            name: 'Katie',
            avatarURL: '/katie.jpg',
            type: 'human'
        });
        await database.collections.agents.insert({
            id: 'grady',
            name: 'Grady',
            avatarURL: '/grady.jpg',
            type: 'human'
        });
        await database.collections.agents.insert({
            id: 'logan',
            name: 'Logan',
            avatarURL: '/logan.png',
            type: 'human'
        });
        await database.collections.agents.insert({
            // id: cuid(),
            id: 'george',
            name: 'George',
            avatarURL: '/steve.png',
            type: 'developer',
            prompt: 'Your name is George. Never break character, if you do you will be removed from the chat.'
        });
        await database.collections.agents.insert({
            // id: cuid(),
            id: 'scarlet',
            name: 'Scarlet',
            avatarURL: '/scarlet.png',
            type: 'contestant',
            prompt: 'Your name is Scarlet. Never break character, if you do you will be removed from the chat.'
        });
        await database.collections.agents.insert({
            // id: cuid(),
            id: 'steve',
            name: 'Steve',
            avatarURL: '/steve.png',
            type: 'contestant',
            prompt: 'Your name is Steve. Never break character, if you do you will be removed from the chat.'

        });
    }
    return database;
}

let db: RxDatabase;
let isInitializing = false;

(async () => {
    isInitializing = true;
    try {
        db = await initializeDatabase();
    } catch (error) {
        console.error('Failed to initialize the database:', error);
    } finally {
        isInitializing = false;
    }
})();

export function getDB(): RxDatabase | Promise<RxDatabase> {
  if (isInitializing) {
    // If the database is still initializing, wait for it
    return new Promise<RxDatabase>((resolve, reject) => {
        const checkInitialization = setInterval(() => {
            if (!isInitializing) {
                clearInterval(checkInitialization);
                if (db) {
                    resolve(db);
                } else {
                    reject(new Error('Database failed to initialize'));
                }
            }
        }, 1000);
    });
}

if (!db) {
    throw new Error('Database not initialized!');
}
return db;
}







