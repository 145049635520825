import { useState } from 'react';
import './App.css';
import Environment from './components/Environment';
import EnvSelector from './components/EnvSelector';
import CreateEnvironment from './components/CreateEnvironment';
import { useDatabase } from './components/DatabaseContext';
import  Cookies  from 'js-cookie';

function App() {

    const [selected, setSelected] = useState<Environment | null>(null);
    const [localAgent, setLocalAgent] = useState<Agent>({
        id: Cookies.get('userId') || '',
        name:  Cookies.get('userId') || '',
        avatarURL: 'https://via.placeholder.com/150',
        prompt: ''
    } as Agent); // State to store the value from the cookie
       
    return (
        <div className="flex">
            <EnvSelector selected={selected} setSelected={setSelected} />
            {selected && <Environment environment={selected} localAgent={localAgent}/>}
            {!selected && <CreateEnvironment setSelected={setSelected} localAgent={localAgent} setLocalAgent={setLocalAgent}/>}

        </div>
    );
}

export default App;
