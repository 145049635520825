import ReactMarkdown from 'react-markdown';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import php from 'react-syntax-highlighter/dist/esm/languages/hljs/php';
import { useDatabase } from './DatabaseContext';
import { RxDatabase, RxDatabaseBase, RxDocument } from 'rxdb';
import { BehaviorSubject, switchMap } from 'rxjs';
import { useStateObservable } from '@react-rxjs/core';
import { useEffect, useState } from 'react';
import {BioModal} from '../Modal'; // Import the Modal component
import Cookies from 'js-cookie';


SyntaxHighlighter.registerLanguage('php', php);



const Message = (props: { initialMessage: Message }) => {

    const db = useDatabase();
    const [agent, setAgent] = useState<Agent>({
        id: Cookies.get('userId') || '',
        name:  Cookies.get('userId') || '',
        avatarURL: 'https://via.placeholder.com/150',
        prompt: ''
    } as Agent);
    const [message, setMessage] = useState<Message>(props.initialMessage);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [agentPrompt, setAgentPrompt] = useState('');


    useEffect(() => {
        db.collections.agents.findOne(message.agentId).$.subscribe(agent => {
            setAgent(agent);
        });
        const message$ = db.collections.messages.findOne(props.initialMessage.id).$ as BehaviorSubject<Message>;
        const messageSub = message$.subscribe(message => {
            setMessage(message);
        });
        return () => {
            messageSub.unsubscribe();
        }
    }, []);

    const handleProfileClick = () => {
        setIsModalOpen(true); // Open the modal
        setAgentPrompt(agent.prompt || ''); // Initialize the textarea with the agent's prompt
    };

    const savePrompt = () => {
        db.collections.agents.findOne(agent.id).exec().then(agent => {
           agent.patch({ prompt: agentPrompt })
        });
        setIsModalOpen(false); // Open the modal

    }

    const handleAgentPromptChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAgentPrompt(event.target.value);
    };

    if (message.content) {
        return (
            <>
                <div className="message w-full">
                    <div className="flex items-center">
                        <img
                            src={agent.avatarURL}
                            alt="Profile"
                            className={`self-start w-[40px] h-[40px] mr-4 ${!agent.ai ? 'rounded-full' : 'aspect-square'}`}
                            title={agent.name}
                            onClick={handleProfileClick} // Add the click handler here
                            style={{ cursor: 'pointer' }} // Add the hover style here
                        />
                        <div className="message-content w-[490px]">
                            <ReactMarkdown
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '');
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                language={match[1]}
                                                style={tomorrowNight}
                                                customStyle={{ margin: '25px 0', overflowX: 'scroll', fontSize: '12px', lineHeight: '1.5' }}
                                            >
                                                {String(children).replace(/\n$/, '')}
                                            </SyntaxHighlighter>
                                        ) : (
                                            <code className={className} {...props}>
                                                {children}
                                            </code>
                                        );
                                    },
                                }}
                            >
                                {message.content}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>

                <BioModal isShowing={isModalOpen} hide={() => setIsModalOpen(false)} save={savePrompt}>
                <h2>Agent Information</h2>
                <p>Name: {agent.name}</p>
                <textarea 
                    placeholder='system prompt' 
                    className='w-full min-h-[200px] border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2 resize-none'
                    value={agentPrompt} 
                    onChange={handleAgentPromptChange}
                >
                </textarea>
            </BioModal>
            </>
        );
    }
    return <div></div>;
};

export default Message;