import React, { useEffect, useState } from 'react';
import { FaComments, FaUserSecret, FaCode } from 'react-icons/fa';
import { useDatabase } from './DatabaseContext';
import cuid from 'cuid';
import { UserSelectModal } from '../Modal';
import Cookies from 'js-cookie';
import { map, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { syncEnvironmentList } from '../connections/externalAPI';

interface CreateEnvironmentProps {
    setSelected: React.Dispatch<React.SetStateAction<Environment | null>>;
    setLocalAgent: React.Dispatch<React.SetStateAction<Agent>>;
    localAgent: Agent;
}

const NewEnvironmentSelector: React.FC<CreateEnvironmentProps> = ({setSelected, setLocalAgent, localAgent}) => {
    const db = useDatabase();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [environments, setEnvironments] = useState<Environment[]>([]);
   
    useEffect(() => {
        //if user is not set, open modal
        if (!Cookies.get('userId')) {
            setIsModalOpen(true);
        }

        const privateEnvs = db.collections.environments
        .find({
            selector: {
                public: false
            }
        })
        .sort({ createdAt: 'desc' })
        .limit(10).$ as BehaviorSubject<Environment[]>;

        const publicEnvs = db.collections.environments
        .find({
            selector: {
                public: true
            }
        })
        .sort({ createdAt: 'desc' })
        .limit(10).$ as BehaviorSubject<Environment[]>;

        const mergedEnvs = combineLatest([privateEnvs, publicEnvs]).pipe(
            map(([privateEnvironments, publicEnvironments]) => {
                // Here you can merge, concatenate, or manipulate the data as needed
                return [...privateEnvironments, ...publicEnvironments];
            })
        );
        mergedEnvs.subscribe(environments => {
            console.log('subscrition notify environments:', environments)
            setEnvironments(environments);
        });

        const localAgentCookie = Cookies.get('userId');
        if (localAgentCookie) {
            setUser(localAgentCookie);
        }

        const syncEnvironments = async () => {
            await syncEnvironmentList(db);
        };
        syncEnvironments();


    }, []);


    const handleGuessWhoClick = async (isPublic:boolean) => {
        // Ensure you have access to the database instance

        const generateRandomString = (length: number) => {
            const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                result += characters.charAt(randomIndex);
            }
            return result;
        };

        const newEnvironment = {
            id: cuid(), // Generate a unique ID
            name: generateRandomString(5),
            createdAt: new Date().toISOString(),
            _deleted: false,
            type: "Game",
            agentIds: ['bradley', 'katie', 'grady', 'logan', 'steve', 'scarlet'],
            public: isPublic,
            hostAgent: localAgent.id // Populate with relevant agent IDs
        };
        // Add the new environment to the RxDB collection
        try {
            await db.environments.insert(newEnvironment);
            console.log("New environment created:", newEnvironment);
            setSelected(newEnvironment);
        } catch (error) {
            console.error("Error creating environment:", error);
        }
    };

    const setUser = (userId: string) => {
        setIsModalOpen(false);
        Cookies.set('userId', userId);
        db.collections.agents.findOne(userId).$.subscribe(agent => {
            setLocalAgent(agent);
        });
        console.log('User selected:', userId);
    }

    return (
        <>
        <div className="p-5 text-center">
            <p>Hello {localAgent.name}</p>

            <h1 className="text-2xl font-bold mb-5">Choose an environment</h1>
            
            <div className="mb-5">
                <h2 className="text-xl font-semibold mb-3">Local</h2>
                {environments
                    .filter(env => env.public === false) // Filter environments with public=false
                    .map((env) => (
                        <button
                            key={env.id}
                            className="m-2 p-2 border border-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                            onClick={() => setSelected(env)}
                        >
                            {env.name}
                        </button>
                ))}


                <button 
                    
                    className="m-2 p-2 border border-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    onClick={() => handleGuessWhoClick(false)}
                >
                    <FaUserSecret className="inline mr-2" />
                    Create
                </button>
                
            </div>

            <div>
                <h2 className="text-xl font-semibold mb-3">Online</h2>
                {environments
                    .filter(env => env.public === true) // Filter environments with public=false
                    .map((env) => (
                        <button
                            key={env.id}
                            className="m-2 p-2 border border-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                            onClick={() => setSelected(env)}
                        >
                            {env.name}
                        </button>
                ))}
                <button className="m-2 p-2 border border-blue-500 rounded hover:bg-blue-500 hover:text-white transition-colors"
                    onClick={() => handleGuessWhoClick(true)}
                >
                    
                    <FaUserSecret className="inline mr-2" />
                   Create
                </button>
              
            </div>
        </div>
        <UserSelectModal isShowing={isModalOpen} save={setUser} />
        </>
    );
}

export default NewEnvironmentSelector;
