import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { DatabaseProvider } from './components/DatabaseContext';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
       <DatabaseProvider>
        <App/>
      </DatabaseProvider>
    </React.StrictMode>
  
);


