import { FromSchema, JSONSchema } from "json-schema-to-ts";

// Messages Schema
export const messageSchema = {
    version: 0,
    type: 'object',
    primaryKey: 'id',
    properties: {
      id: {
        type: 'string',
        maxLength: 100
      },
      environmentId: {  // Foreign key to link to conversation
        type: 'string',
        maxLength: 100
      },
      agentId: {  // Foreign key to link to user
        type: 'string',
        maxLength: 100
      },
      hostId: {
        type: 'string',
        maxLength: 100
      },
      readBy: {
        type: 'array',
        items: {
          type: 'string'
        }
      },
      content: {
        type: 'string'
      },
      complete: {
        type: 'boolean'
      },
      createdAt: {  // Timestamp for the message
        type: 'string',
        format: 'date-time'
      },
      updatedAt: {  // Timestamp for the message
        type: 'string',
        format: 'date-time'
      }
    },
    required: ['id', 'environmentId', 'content', 'readBy', 'updatedAt', 'agentId', 'complete']
  } as const;

  declare global {
    type Message = FromSchema<typeof messageSchema>;
  }
