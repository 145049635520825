import { createContext, useContext } from 'react';
import React, { useEffect, useState } from 'react';
import { getDB } from '../connections/LocalDB';
import { RxDatabase } from 'rxdb';

const DatabaseContext = createContext<RxDatabase | null>(null);

export const useDatabase = (): RxDatabase => {
  const context = useContext(DatabaseContext);
  if (!context) {
    throw new Error("useDatabase must be used within a DatabaseProvider");
  }
  return context;
};

interface DatabaseProviderProps {
    children: React.ReactNode;
  }

  export const DatabaseProvider: React.FC<DatabaseProviderProps> = ({ children }) => {
    const [database, setDatabase] = useState<RxDatabase | null>(null);
    const [dbError, setDbError] = useState<Error | null>(null);

    useEffect(() => {
        async function initDB() {
          try {
            const db = await getDB();
            setDatabase(db);
          }
          catch(error) {
            setDbError(error as Error);
          }
        }
        initDB();
    }, []);

    if (dbError) return <div>Error initializing database: {dbError.message}</div>;
    if (!database) return <div>Loading database...</div>;  // Or some other loading indication

    return <DatabaseContext.Provider value={database}>{children}</DatabaseContext.Provider>;
};


export default DatabaseContext;