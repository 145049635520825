import { FromSchema, JSONSchema } from "json-schema-to-ts";

// environment Schema
export const environmentSchema = {
  version: 0,
  type: 'object',
  primaryKey: 'id',
  properties: {
    id: {
      type: 'string',
      maxLength: 100
    },
    name: {
      type: 'string'
    },
    createdAt: {  // For the creation timestamp of the environment
      type: 'string',
      format: 'date-time'
    },
    updatedAt: {  // For the last updated timestamp of the environment
      type: 'string',
      format: 'date-time'
    },
    _deleted: {  // Archival field
      type: 'boolean'
    },
    type: {
      type: 'string' //single chat, multichat, game, self builder
    },
    systemMessage: {
      type: 'string' //for openai system messages
    },
    agentIds: {
      type: 'array',
      items: {
        type: 'string'
      },
      minItems: 1  // If you expect at least one userId in the array
    },
    hostAgent:{
      type: 'string'
    },
    public: {
      type: 'boolean'
    }
  },
  required: ['id', 'name', 'agentIds', 'type']
} as const;



declare global {
  type Environment = FromSchema<typeof environmentSchema>;
}
