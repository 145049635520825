import React from 'react';
import ReactDOM from 'react-dom';

type BioModalProps = {
  isShowing: boolean;
  hide: () => void;
  save: () => void;
  children: React.ReactNode;
};

type UserSelectModalProps = {
  isShowing: boolean;
  save: (userId: string) => void;
};

export const BioModal: React.FC<BioModalProps> = ({ isShowing, hide, save, children }) => {
  if (!isShowing) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg z-50 w-2/3 h-2/3 max-w-2xl max-h-96 overflow-auto">
        {children}
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={hide}>
          Close
        </button>
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={save}>
          Save
        </button>
      </div>
    </div>,
    document.getElementById('modal-root') as HTMLElement
  );
};

export const UserSelectModal: React.FC<UserSelectModalProps> = ({ isShowing, save }) => {
  if (!isShowing) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg z-50 w-2/3 h-2/3 max-w-2xl max-h-96 overflow-auto">
        <h2>Select User</h2>
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => save('bradley')}
          value="bradley">
          Bradley
        </button>
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => save('katie')}
          value="katie">
          Katie
        </button>
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => save('grady')}
          value="grady">
          Grady
        </button>
        <button 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => save('logan')}
          value="logan">
          Logan
        </button>
      </div>
    </div>,
    document.getElementById('modal-root') as HTMLElement
  );
}

