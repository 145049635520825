import { RxDatabase, RxDocument } from 'rxdb';
import { merge, mergeMap, filter, map, distinct, Observable, BehaviorSubject, Subscription, async } from 'rxjs';
import cuid from 'cuid';
import { concatMap, Subject, from, mergeAll, delay, of } from 'rxjs';
import { Chat, ChatCompletionChunk, ChatCompletionMessage, ChatCompletionMessageParam } from 'openai/resources/chat';
import OpenAI from 'openai';
import Cookies from 'js-cookie';

let key1 = 'sk-CskZ4J7LCgJY70gX0UeTT3'
let key2 = 'BlbkFJ3UtQjDJiHb2H2muuvALn'

const openai = new OpenAI({
    apiKey: key1+key2, dangerouslyAllowBrowser: true
});
export class AIAgent {

  public db: RxDatabase;
  public agent$: BehaviorSubject<Agent>;
  public agent: Agent;
  public environment$: BehaviorSubject<Environment>;
  public isBusy: boolean = false;
  public queuedMessages: boolean = false;
  public agentId: string;
  public hostId: string;
  public multiUser: boolean = false;
  public envId: string;
  // public messages$: BehaviorSubject<Message[]>;

  constructor(db: RxDatabase, agentId: string, envId: string) {
    this.agentId = agentId;
    this.db = db;
    this.envId = envId;
    this.agent = {} as Agent;
    this.agent$ = db.agents.findOne(agentId).$;
    this.hostId = Cookies.get('userId') ?? '';
    this.agent$.subscribe(agent => {
      this.agent = agent;
    });
    this.environment$ = db.environments.findOne(envId).$;
    this.environment$.subscribe(environment => {
      if(!environment) return;
      console.log('environment', environment.agentIds.length > 2);
      this.multiUser = environment.agentIds.length > 2;
    });

  }

  async readMessages(messages: Message[]): Promise<boolean> {
      
    //mark messages as read
    const dbMessages = await Promise.all(messages.map(async message => {
      return await this.db.messages.findOne(message.id).exec();
  }));

  let unReadMessages = 0;
  for (const dbMessage of dbMessages) {
    if(!dbMessage.readBy.includes(this.agentId)){
      unReadMessages++;
      await dbMessage.incrementalPatch({ readBy: dbMessage.readBy.concat(this.agentId) });
    }
  }
  if(unReadMessages === 0){
    console.log('already read these messages', this.agentId, messages.slice(-10));
    return false;
  }


      if(messages[messages.length - 1]?.agentId === this.agentId || messages.length === 0){
        console.log('ignoring own message or no messages found', this.agentId, messages.slice(-10))
        return false;
      }
      const lastMessage = messages[messages.length - 1];
      const streamMessages = await Promise.all(
        

        messages.map(async message => {
          const msgAgent = await this.db.agents.findOne(message.agentId).exec();
          let content = message.content;
          if (!message.content.startsWith(msgAgent.name)) {
            const isAI = msgAgent.type !== 'human' ? ' (AI):' : ' (Human):';
            content = msgAgent.name + isAI + message.content;
          }
          return {
            role: this.agentId === msgAgent.id ? 'assistant' : 'user',
            content: content,
          };
        })
      ) as ChatCompletionMessageParam[];
  
      streamMessages.unshift({ role: 'system', content: this.agent.prompt ?? '' });
  
      console.log('starting assessment', this.agentId, streamMessages)
      const assessmentValue = this.multiUser ? await this.getAssessment(streamMessages) : true;
      console.log('assessment complete', this.agentId, assessmentValue);
      
      if(assessmentValue == true){
        await this.reply(streamMessages, lastMessage);
        return true;
      }
      return false;
  }

  async reply(streamMessages: ChatCompletionMessageParam[], lastMessage: Message) {
    //wait 500ms and give test reply
    await new Promise(r => setTimeout(r, 500));
    console.log('reply');


    const aiMessageId = cuid();
    
    // let msg = await this.db.messages.insert(placeholderMessage);

    const messageCompletion = await openai.chat.completions.create({
      model: 'gpt-4-1106-preview',
      messages: streamMessages,
    });

    const placeholderMessage = { 
      complete: true, 
      id: aiMessageId, 
      environmentId: lastMessage.environmentId, 
      agentId: this.agentId,
      hostId: this.hostId,
      content: messageCompletion.choices[0].message.content, 
      createdAt: new Date().toISOString(), 
      updatedAt: new Date().toISOString(),
      readBy: [this.agentId, this.hostId] 
    };
    const msg = this.db.messages.insert(placeholderMessage);

    // await msg.incrementalPatch({content: messageCompletion.choices[0].message.content, updatedAt: new Date().toISOString(), complete: true});
    console.log('message complete', msg);
    await new Promise(r => setTimeout(r, 100));

  }
  //   const messageStream = await openai.chat.completions.create({
  //     model: 'gpt-4-1106-preview',
  //     messages: streamMessages,
  //     stream: true
  //   });

  //   for await (const chunk of messageStream) {
  //     const delta = chunk.choices[0]?.delta?.content;
  //     const finished = chunk.choices[0]?.finish_reason !== null;

  //     if (!finished && typeof(delta) === 'string') {
  //       msg = await msg.incrementalPatch({ content: msg.content + delta, updatedAt: new Date().toISOString() });
  //     }
  //     if (finished) {
  //       if (msg.content.startsWith(this.agent.name)) {
  //         msg = await msg.incrementalPatch({ content: msg.content.slice(msg.content.indexOf(':') + 1), updatedAt: new Date().toISOString() });
  //       }
  //       await msg.patch({ complete: true });
  //       console.log(this.agentId, 'message complete, waiting 100ms');
  //       //wait 500ms
  //       await new Promise(r => setTimeout(r, 100));
  //       console.log(this.agentId, 'wait complete');

  //       break;
  //     }
  //   }
  // }

  async getAssessment(messages: ChatCompletionMessageParam[]){
    const assessment = await openai.chat.completions.create({
      // model: 'gpt-3.5-turbo',
      model: 'gpt-4-1106-preview',
      messages: messages.slice(-20),
      tools: [{
        type: 'function',
        function: {
          description: 'This is a multi-user chat room. In a multi-user chat, the participants dont always need to respond to every message. The purpose of this function is to assesses if the agent needs to send a new message.',
          name: 'determine_response_needed',
          parameters: {
            type: "object",
            properties: {
                response_needed: {
                    type: "boolean",
                    description: "Whether or not the agent needs to send a new message. If unsure, the default should be false.",
                },
            },
            required: ["response_needed"]
          }
        }
      }],
      tool_choice: { type: 'function', function: { name: 'determine_response_needed' } }
    });
    let assessmentValue = 0;
    if (assessment.choices[0]?.message?.tool_calls?.[0]?.function?.arguments) {
      assessmentValue = JSON.parse(assessment.choices[0].message.tool_calls[0].function.arguments).response_needed;
      
      console.log('assessment', assessmentValue, this.agent.name, messages.slice(-10));
    }
    return assessmentValue;
  }

  cleanup() {
   
}
}